import React from "react"
import { Container } from "components/library"
import { graphql } from "gatsby"
import { NotionArticle, transformJob } from "utils"
import { Linkedin } from "react-feather"
import { Whatsapp } from "components/library/icons/social";
import { PositionBrief } from "components/pages/careers/position/position-brief"
import { PositionDetails } from "components/pages/careers/position/position-details"

function fixedEncodeURIComponent(str: string) {
	return encodeURIComponent(str).replace(/[!'()*]/g, function (c) {
		return "%" + c.charCodeAt(0).toString(16)
	})
}

type P = {
	pageContext: {
		job: NotionArticle
	}
}

const CareerPosition = ({ pageContext: { job } }: P) => {
	const transformedJob = transformJob(job)

	return (
		<Container className="flex flex-col gap-12 py-20">
			<PositionBrief job={transformedJob} />
			<PositionDetails job={job} />

			<div className="flex flex-col sm:flex-row items-center gap-2 sm:gap-6">
				<a
					target="_blank"
					href={`whatsapp://send?text=${"Hey, Apply for " +
						transformedJob.position +
						" at " +
						fixedEncodeURIComponent(location.href)
						}`}
					data-action="share/whatsapp/share"
					className="bg-[#075E54] flex gap-2 items-center text-white px-4 py-2 rounded-md w-full sm:max-w-max"
				>
					<Whatsapp size={16} />
					Share on Whatsapp
				</a>

				<a
					target="_blank"
					className="bg-[#0077b5] flex gap-2 items-center text-white px-4 py-2 rounded-md w-full sm:max-w-max"
					href={`https://www.linkedin.com/sharing/share-offsite/?url=${fixedEncodeURIComponent(
						location.href
					)}`}
				>
					<Linkedin size={16} />
					Share on Linkedin
				</a>
			</div>
		</Container>
	)
}

export default CareerPosition

// FOR LANGUAGE TRANSLATION
export const query = graphql`
	query ($language: String!) {
		locales: allLocale(filter: { language: { eq: $language } }) {
			edges {
				node {
					ns
					data
					language
				}
			}
		}
	}
`
