import React from "react"
import { NotionArticle } from "utils"
import { NotionRenderer } from "react-notion-x"

type P = {
	job: NotionArticle
}

export const PositionDetails = ({ job }: P) => {
	return (
		<div className="prose w-full sm:max-w-3xl prose-li:marker:text-inherit">
			<NotionRenderer recordMap={job} />
		</div>
	)
}
